<template>
    <div>
        <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
         <div class='publicServiceContent'>
             <div class='publicServiceCondition'>
                 <div class="flex publiceCommon">
                    <div class='title'>用工类型</div>
                    <div  class='typeName' @click='onEmployment(index, item.id)' :class='employmentActive==index?"active":""' v-for='(item, index) in employmentType' :key='index+"l"'>{{item.name}}</div>
                </div>
                <div class="flex publiceCommon publiceCommonBot">
                    <div class='title'>排序</div>
                    <div  class='typeName' @click='onJoinWantJob(index, item.id)' :class='jobWantActive==index?"active":""' v-for='(item, index) in jobWantList' :key='index+"s"'>{{item.name}}</div>
                </div>
             </div>
            <div v-if='page.total'>
                 <div v-if ='employmentActive==0'>
                     <el-table
                        :data="list"
                        :row-class-name = "tabRowClassName"
                        @row-click= 'onRowClick'
                        header-row-class-name = 'headerRowClassName'
                        key='list'
                        align = 'center'>
                        <el-table-column
                        prop="position"
                        label="职位名"
                        align = 'center'
                        width="200">
                        </el-table-column>
                        <el-table-column
                        prop="recordName"
                        label="公司名"
                        align = 'center'
                        width="300">
                        <template slot-scope="scope">
                            <span >{{ scope.row.recordName || scope.row.linkman }}</span>
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="salary"
                        align = 'center'
                        label="薪资">
                        <template slot-scope="scope">
                            <span class='wages'>{{ scope.row.salary === "0-0" ? "面议" : scope.row.salary }}</span>
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="addressDetails"
                        empty-text='暂无地址'
                        align = 'center'
                        label="发布日期">
                        <template slot-scope="scope">
                            <span>{{ scope.row.createdDate && scope.row.createdDate.split(" ")[0] }}</span>
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop=""
                        align = 'center'
                        label="">
                        <template>
                            <button class='resumeDelivery' @click='onResumeDelivery()'>简历投递</button>
                        </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div v-else>
                     <el-table
                        :data="jobWant"
                        :row-class-name = "tabRowClassName"
                        @row-click= 'onRowClick'
                        header-row-class-name = 'headerRowClassName'
                        key='jobWant'
                        align = 'center'>
                        <el-table-column
                        prop="position"
                        label="求职岗位"
                        align = 'center'
                        width="200">
                        </el-table-column>
                        <el-table-column
                        prop="salary"
                        label="期望薪资"
                        align = 'center'
                        width="200">
                        <template slot-scope="scope">
                            <span class='wages'>{{ scope.row.salary === "0-0" ? "面议" : scope.row.salary }}</span>
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="linkman"
                        align = 'center'
                        label="联系人">
                        </el-table-column>
                        <el-table-column
                        prop="addressDetails"
                        empty-text='暂无地址'
                        align = 'center'
                        label="求职地">
                        </el-table-column>
                    </el-table>
                </div>
                <pagination v-if='page.total > param.pageSize' @pageChange="pageChange" :total='page.total' :currentPage='page.currentPage' :pageSize='page.pageSize'></pagination>
            </div>
           <div v-else>
                <no-data></no-data>
            </div>
        </div>
    </div>
</template>
<script>
import pagination from '@/component/pagination/index.vue';
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import Vue from 'vue';
import {Table, TableColumn} from 'element-ui';
import noData from '@/component/noData/index.vue';
Vue.use(Table);
Vue.use(TableColumn);
export default {
    components: {
        pagination,
        nfBreadcrumb,
        noData
    },
    data() {
        return {
            employmentActive: 0,
            jobWantActive: 0,
            employmentType: [{
                id: 0,
                name: '招聘'
            }, {
                id: 1,
                name: '求职'
            }],                
            jobWantList: [{
                id: '',
                name: '全部'
            }, {
                id: 0,
                name: '薪资由高到低'
            }, {
                id: 1,
                name: '薪资由低到高'
            }, {
                id: 2,
                name: '发布日期由近到远'
            }, {
                id: 3,
                name: '发布日期由远到近'
            }],
            list: [],
            page: {
                total: 0,
                currentPage: 1,
                pageSize: 10
             },
             param: {
                pageNum: 1,
                pageSize: 10,
                search: "",
                sort: '',
                status: 2,
                tags: ""
             },
             jobWant: [],
             breadList: [{
                name: '南繁服务'
            }, {
                name: '用工服务'
            }]
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        onEmployment(idx, id) {
            this.employmentActive = idx;
            this.param.cityType = id;
            this.page.currentPage = 1;
            if (this.employmentActive === 0) {
                this.initData();
            } else {
                this.initDataJob();
            }
        },
        onJoinWantJob(idx, id) {
            this.jobWantActive = idx;
            this.param.sort = id;
            this.page.currentPage = 1;
            if (this.employmentActive === 0) {
                this.initData();
            } else {
                this.initDataJob();
            }
        },
        pageChange (item) {
            this.page.currentPage = item.page_index;
            this.page.pageSize = item.page_limit;
            if (this.employmentActive === 0) {
                this.initData();
            } else {
                this.initDataJob();
            }
        },
        // 招聘列表
        async initData() {
            this.param.pageNum = this.page.currentPage;
            const api = this.$fetchApi.queryRecruit.api;
            const data = await this.$fetchData.fetchPost(this.param, api, "json");
            if (data.code === '200' && data.result) {
                this.list = data.result.rows;
                this.page.total = data.result.total;
            }
        },
        // 求职列表
        async initDataJob() {
            this.param.pageNum = this.page.currentPage;
            const api = this.$fetchApi.queryPosition.api;
            const data = await this.$fetchData.fetchPost(this.param, api, "json");
            if (data.code === '200' && data.result) {
                this.jobWant = data.result.rows;
                this.page.total = data.result.total;
            }
        },    
        tabRowClassName({  rowIndex}){
            const index = rowIndex + 1;
            if (index % 2 === 1) {
                return 'rowActive lineActive';
            }
            return 'lineActive';

        },
        // 投递简历
        onResumeDelivery() {
            this.$router.push({
                path: '/downLoad'
            });
        },
        onRowClick(data) {
            this.$router.push({
                path: '/publicService/employmentDetail',
                query: {
                    type: this.employmentActive,
                    id: data.id
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
    .publicServiceContent{
        width:1180px;
        margin:0 auto;
        font-size: 16px;
        color: #333333;
        .publicServiceCondition{
            border: solid 1px #d9d9d9;
            margin-top:20px;
        }
    }
    .publiceCommonBot{
        border-top: dashed 1px #d9d9d9;
    }
    .publicService{
        font-size: 16px;
        line-height: 16px;
        color: #333;
    }
    .title{
        margin-right:20px;
        height:30px;
        line-height: 30px;
        font-size: 16px;
        font-weight: bold;
    }
    .publiceCommon{
       padding:19px 0;
        margin-left:16px;
    }
    .typeName{
        padding:0 11px;
        height:30px;
        line-height: 30px;
        margin-right:10px;
    }
    .active{
        color:#265ae1;
    }
    .listItem{
        padding:20px 0;
        border-top:1px solid #ccc;
        border-bottom:1px solid #ccc;
    }
    .commonFont{
        font-size: 18px;
        color:#333;
    }
    .smallTitle {
        font-size: 16px;
        color:#757575;
    }
    .publicServiceContent /deep/ .el-table{
        font-size:18px;
        color:#333;
        width:100%;
        margin:40px 0;
    }
    .publicServiceContent /deep/ .el-table .rowActive{
        background:#eaf2ff;
    }
    .publicServiceContent /deep/ .el-table .lineActive{
        height:54px;
    }
   .publicServiceContent /deep/ .headerRowClassName{
        font-size: 14px;
        color: #757575;
        font-weight: normal;
    }
    .wages{
        color: #ff5454;
    }
    .resumeDelivery {
        width: 90px;
        height: 36px;
        background-color: #265ae1;
        border-radius: 2px;
        color: #fff;
        text-align: center;
        line-height: 36px;
        outline: none;
        border: none;
        padding:0;
        font-size: 16px;
    }
</style>